import React from "react"
import { CartProvider } from "react-use-cart"
import SEO from "./SEO"
import gradient from "@static/Honey.svg"
import LoadCookies from "@components/LazyComponents/LoadCookies"
import { ThemeContext } from "./themeContext"
import "./PriceBox/BuyNow/snipcart.css"

const Layout = ({ children }) => {
  const { theme, setTheme } = React.useContext(ThemeContext)
  return (
    <div>
      <SEO />
      <CartProvider>
        <div className="flex flex-col min-h-screen bg-zircon dark:bg-base-200">
          {children}
        </div>
      </CartProvider>
      {/* <LoadCookies/> */}
    </div>
  )
}

export default Layout

import React from "react"
import { useCart } from "react-use-cart"
import { FaTimes } from "react-icons/fa"
import { Link } from "gatsby"

export default function Favorites() {
  const { isEmpty, items, removeItem, emptyCart } = useCart()

  if (isEmpty)
    return <p class="p-5 opacity-50">Your Favorites List is currently empty.</p>

  return (
    <>
      <div class="flex justify-between flex-row my-3">
        <h3 class="text-lg font-bold">Favorites</h3>
        {!isEmpty && (
          <div
            onClick={emptyCart}
            className="badge pointer cursor-pointer self-center"
          >
            Empty Favorites
          </div>
        )}
      </div>
      <div class="mb-4">
        <div className="py-2">
          <div className="max-w-2xl mx-auto mr-2">
            <div className="relative">
              <div
                className="absolute inset-0 flex items-center"
                aria-hidden="true"
              >
                <div className="w-full border-t border-gray-300"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {items.map(item => (
          <div class="flex justify-between flex-row mb-4">
            <Link
              class="w-3/6"
              to={`/${item.name.toLowerCase()}`}
              key={item.id}
            >
              <h4 class="font-bold">{item.name}</h4>
            </Link>
            <p>
              {item.price ? "$" : ""}
              {item.price}
            </p>
            <div
              class="cursor-pointer self-center mr-2"
              onClick={() => removeItem(item.id)}
            >
              <FaTimes />
            </div>
          </div>
        ))}
      </div>
    </>
  )
}
